<template>
  <div class="Userhl_fazp">
    <div class="fxiangmu_top">
      <ul class="fxiangmu_topul">
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">展览标题</div>
          <div class="fxiangmu_toplir">
            <el-input v-model="zl_name" placeholder="请输入展览标题"></el-input>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">展览类型</div>
          <div class="fxiangmu_toplir">
            <el-select v-model="zl_type" placeholder="请选择行业领域">
              <el-option
                v-for="item in options"
                :key="item.classid"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="!schooltype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">展览细分</div>
          <div class="fxiangmu_toplir">
            <el-select v-model="zl_xinfen" placeholder="请选择展览细分">
              <el-option
                v-for="item in xifenoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="gerenzhanid_type">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">展览人绑定</div>
          <div class="fxiangmu_toplir">
            <el-input
              v-model="gerenzhanid"
              style="width: 50%; margin-right: 5%"
              placeholder="请输入 '已绑定和作营平台' 的手机号或者 '用户名' 进行查询"
            ></el-input>
            <el-select v-model="gerenzhanid_id" placeholder="请选择展览人">
              <el-option
                v-for="item in gerenzhanid_idoptions"
                :key="item.userid"
                :label="item.username"
                :value="item.userid"
              >
              </el-option>
            </el-select>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="schooltype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">院校名称</div>
          <div class="fxiangmu_toplir" style="position: relative">
            <el-input
              @focus="schoolfocus"
              v-model="school"
              placeholder="请搜索院校"
            ></el-input>
            <ul class="schoollb" v-show="schoollbtype">
              <li
                v-for="(item, index) in schoollbli"
                :key="index"
                @click="schoollbliclick(item.name, item.id)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">展览封面</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn hdfengmian">
            <img :src="fmimg" alt="" />
            <el-button plain icon="el-icon-picture">
              上传封面
              <Uploadimg @uploadimg="uploadimgurl" :imgall="imgalls" />
            </el-button>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="!schooltype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">顶部logo</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn hdfengmian">
            <img :src="tlogomimg" style="height: auto" alt="" />
            <el-button style="margin-top: 1rem" plain icon="el-icon-picture">
              上传顶部logo
              <Uploadimg
                @uploadimgheji="upload_tlogo"
                :imgall="imgalls_tlogo"
              />
            </el-button>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="!schooltype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">首屏底图</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn hdfengmian">
            <img :src="spbtmimg" style="height: 14rem" alt="" />
            <el-button style="margin-top: 5rem" plain icon="el-icon-picture">
              上传首屏底图
              <Uploadimg
                @uploadimg="upload_spbtmimg"
                :imgall="imgalls_spbtmimg"
              />
            </el-button>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="!schooltype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">首屏内容</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn hdfengmian">
            <img :src="sptextimg" style="height: 19.3rem" alt="" />
            <el-button style="margin-top: 8rem" plain icon="el-icon-picture">
              上传首屏内容
              <Uploadimg
                @uploadimg="upload_sptextimg"
                :imgall="imgalls_sptextimg"
              />
            </el-button>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="!schooltype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">导航文字颜色</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn">
            <el-color-picker
              v-model="dhtextcolor"
              show-alpha
              :predefine="predefineColors"
            >
            </el-color-picker>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="!schooltype">
          <div class="fxiangmu_toplil"></div>
          <div class="fxiangmu_toplic">主题背景图</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn hdfengmian">
            <img :src="ztbkimg" style="height: 14rem" alt="" />
            <el-button plain style="margin-top: 5rem" icon="el-icon-picture">
              主题背景图
              <Uploadimg
                @uploadimg="upload_ztbkimg"
                :imgall="imgalls_spbtmimg"
              />
            </el-button>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="!schooltype">
          <div class="fxiangmu_toplil"></div>
          <div class="fxiangmu_toplic">底色背景色</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn">
            <el-color-picker
              v-model="bjcolor"
              show-alpha
              :predefine="predefineColors"
            >
            </el-color-picker>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="schooltype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">展览Banner</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn">
            <img :src="banimg" alt="" style="height: 8rem" />
            <el-button plain icon="el-icon-picture" style="margin-top: 2rem">
              上传Banner
              <Uploadimg @uploadimg="uploadimgbanner" :imgall="imgalls2" />
            </el-button>
            <div class="Uploadimgbannertxet">
              建议上传宽高比例为(3.6 : 1)比例的banner
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="fxiangmu_top">
      <ul class="fxiangmu_topul">
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">报名时间范围</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_datebox">
            <div class="fxiangmu_toplir_date">
              <el-date-picker
                v-model="startend"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">主办单位</div>
          <div class="fxiangmu_toplir">
            <el-input
              v-model="zl_danwei"
              placeholder="请输入主办单位"
            ></el-input>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">联系人</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_weituo">
            <div class="fxiangmu_toplir_weituobox">
              <el-input v-model="zl_user" placeholder="请输入联系人"></el-input>
            </div>
            <div
              class="fxiangmu_toplir_weituobox fxiangmu_toplir_weituoboxphone"
            >
              联系方式
            </div>
            <div class="fxiangmu_toplir_weituobox">
              <el-input
                v-model="zl_phone"
                placeholder="请输入联系方式"
              ></el-input>
            </div>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="schooltype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">展览简介</div>
          <div class="fxiangmu_toplir">
            <el-input
              type="textarea"
              autosize
              placeholder="请输入展览简介"
              v-model="textareaall"
            >
            </el-input>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="schooltype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">展览配图</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn">
            <img
              :src="jjimg"
              alt=""
              class="xyzlpeitu"
              style="height: 19.5rem"
            />
            <el-button plain icon="el-icon-picture" class="xyzlpeitubtn">
              上传简介封面
              <Uploadimg @uploadimg="uploadimgjjbanner" :imgall="imgalls3" />
            </el-button>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="schooltype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">学院简介</div>
          <div class="fxiangmu_toplir">
            <el-input
              type="textarea"
              autosize
              placeholder="请输入学院简介"
              v-model="jiyutext"
            >
            </el-input>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="schooltype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">学院配图</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn">
            <img
              :src="jyimg"
              alt=""
              class="xyzlpeitu"
              style="height: 19.5rem"
            />
            <el-button plain icon="el-icon-picture" class="xyzlpeitubtn">
              上传寄语封面
              <Uploadimg @uploadimg="uploadimgjybanner" :imgall="imgalls3" />
            </el-button>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="schooltype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">班级</div>
          <div class="fxiangmu_toplir">
            <ul class="addbanjiul">
              <li v-for="(item, index) in banjivalul" :key="index">
                {{ item }}
              </li>
            </ul>
            <el-button @click="dialogVisible = true">
              <i class="el-icon-circle-plus-outline"></i>
              批量添加班级
            </el-button>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">专业分类</div>
          <div class="fxiangmu_toplir">
            <ul class="addbanjiul">
              <li v-for="(item, index) in zhuanyevalul" :key="index">
                {{ item }}
              </li>
            </ul>
            <el-button @click="zhuanyedialog = true">
              <i class="el-icon-circle-plus-outline"></i>
              批量添加专业
            </el-button>
          </div>
        </li>
        <li class="fxiangmu_topli" v-show="false">
          <div class="fxiangmu_toplil"></div>
          <div class="fxiangmu_toplic">上传水印</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn">
            <img
              :src="shuiyinimg"
              alt=""
              class="xyzlpeitu"
              style="height: auto"
            />
            <el-button
              plain
              icon="el-icon-picture"
              style="margin-top: 4rem !important"
              class="xyzlpeitubtn"
            >
              上传水印
              <Uploadimg
                @uploadimgheji="uploadimgshuiyin"
                :imgall="imgallsfalse"
              />
            </el-button>
          </div>
        </li>
      </ul>
    </div>
    <div class="xiangmusub_btnbox">
      <el-button type="primary" v-show="!schooltype" plain @click="lookzl"
        >预览展览</el-button
      >
      <el-button type="primary" plain @click="subzl">发布展览</el-button>
    </div>
    <!-- 、、、、、、、、、、、、、、、 -->
    <el-dialog
      title="批量添加班级"
      :visible.sync="dialogVisible"
      width="40%"
      class="banjitanbox"
    >
      <p class="banjitanp">注：每个班级之间请务必换行！</p>
      <el-input
        type="textarea"
        autosize
        placeholder="请输入班级（如有多个班级，回车键隔开！）"
        v-model="banjival"
        class="dialogVisiblebanji"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="banjiclick(0)">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="批量添加专业"
      :visible.sync="zhuanyedialog"
      width="40%"
      class="banjitanbox"
    >
      <p class="banjitanp">注：每个专业之间请务必换行！</p>
      <el-input
        type="textarea"
        autosize
        placeholder="请输入班级（如有多个班级，回车键隔开！）"
        v-model="zhuanyeval"
        class="dialogVisiblebanji"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="zhuanyedialog = false">取 消</el-button>
        <el-button type="primary" @click="banjiclick(1)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Uploadimg from "@/components/Uploadimg.vue";
export default {
  components: { Uploadimg },
  data() {
    return {
      /////作品展
      zl_xinfen: "",
      xifenoptions: [
        {
          value: "1",
          label: "多人展",
        },
        {
          value: "2",
          label: "个人展",
        },
      ],
      gerenzhanid: "",
      gerenzhanid_id: "",
      gerenzhanid_idoptions: [],
      gerenzhanid_type: false,
      tlogomimg: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      spbtmimg: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      sptextimg: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      ztbkimg: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      dhtextcolor: "#000",
      bjcolor: "#fff",
      imgalls_spbtmimg: {
        name: "",
        width: 2540,
        height: 1200,
      },
      imgalls_sptextimg: {
        name: "",
        width: 1800,
        height: 1200,
      },
      imgalls_tlogo: {
        name: "complex",
        width: 100,
        height: 100,
      },
      ///////////
      zhuanyedialog: false,
      dialogVisible: false,
      imgalls: {
        name: "",
        width: 400,
        height: 400,
      },
      imgallsfalse: {
        name: "complex",
        width: 200,
        height: 200,
      },
      banjival: "",
      banjivalul: [],
      zhuanyeval: "",
      zhuanyevalul: [],
      imgalls2: {
        name: "",
        width: 400,
        height: 102,
      },
      imgalls3: {
        name: "",
        width:400,
        height: 300,
      },
      predefineColors: [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "rgba(255, 69, 0, 0.68)",
        "rgb(255, 120, 0)",
        "hsl(181, 100%, 37%)",
        "hsla(209, 100%, 56%, 0.73)",
        "#c7158577",
      ],
      schoolid: "",
      schoolhidden: 0,
      schoollbtype: false,
      schoollbli: [],
      schooltype: true,
      school: "",
      options: [
        {
          label: "毕设展",
          value: "1",
        },
        {
          label: "作品展",
          value: "2",
        },
      ],
      zl_name: "", //展览标题
      zl_type: "1", //展览类型
      fmimg: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png", //封面地址
      banimg: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      jjimg: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      jyimg: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      shuiyinimg: "", //水印封面
      startend: "", //展览时间范围
      zl_danwei: "", //主办单位
      zl_user: "", //联系人
      zl_phone: "", //联系电话
      textareaall: "", //展览简介
      jiyutext: "", //学院简介
      userinfo: {},
    };
  },
  methods: {
    lookzl() {
      var that = this;
      that.$message("预览");
    },
    upload_tlogo(rul) {
      console.log(rul);
      this.tlogomimg = rul.src;
    },
    upload_spbtmimg(rul) {
      this.spbtmimg = rul;
    },
    upload_sptextimg(rul) {
      this.sptextimg = rul;
    },
    upload_ztbkimg(rul) {
      this.ztbkimg = rul;
    },

    uploadimgurl(rul) {
      this.fmimg = rul;
    },
    uploadimgbanner(rul) {
      this.banimg = rul;
    },
    uploadimgjjbanner(rul) {
      this.jjimg = rul;
    },
    uploadimgjybanner(rul) {
      this.jyimg = rul;
    },
    uploadimgshuiyin(rul) {
      this.shuiyinimg = rul.src;
    },
    subzl() {
      var that = this;
      if (that.zl_name == "") {
        that.$message("请填写展览标题");
        return false;
      }
      if (
        that.fmimg ==
        "https://task.hozoin.cn/Works/20201104/sj-4369072618.png"
      ) {
        that.$message("请选择封面图片");
        return false;
      }

      if (that.startend == "") {
        that.$message("请填写报名时间范围");
        return false;
      }
      if (that.zl_danwei == "") {
        that.$message("请填写主办单位");
        return false;
      }
      if (that.zl_user == "") {
        that.$message("请填写联系人");
        return false;
      }
      if (that.zl_phone == "") {
        that.$message("请填写联系方式");
        return false;
      }

      if (that.zl_type == "1") {
        //毕设展
        if (that.textareaall == "") {
          that.$message("请填写展览简介");
          return false;
        }
        if (
          that.jjimg ==
          "https://task.hozoin.cn/Works/20201104/sj-4369072618.png"
        ) {
          that.$message("请选择展览配图");
          return false;
        }
        if (
          that.banimg ==
          "https://task.hozoin.cn/Works/20201104/sj-4369072618.png"
        ) {
          that.$message("请选择Banner图片");
          return false;
        }
        if (that.schoolid == "") {
          that.$message("请填写院校");
          return false;
        }
        if (that.jiyutext == "") {
          that.$message("请填写学院简介");
          return false;
        }
        if (
          that.jyimg ==
          "https://task.hozoin.cn/Works/20201104/sj-4369072618.png"
        ) {
          that.$message("请输入学院配图");
          return false;
        }
        if (that.banjivalul.length == 0) {
          that.$message("请填写班级");
          return false;
        }
        if (that.zhuanyevalul.length == 0) {
          that.$message("请填写专业");
          return false;
        }
      }else{
        if (
          that.tlogomimg ==
          "https://task.hozoin.cn/Works/20201104/sj-4369072618.png"
        ) {
          that.$message("请上传顶部logo");
          return false;
        }
        if (
          that.spbtmimg ==
          "https://task.hozoin.cn/Works/20201104/sj-4369072618.png"
        ) {
          that.$message("请上传首屏底图");
          return false;
        }
        if (
          that.sptextimg ==
          "https://task.hozoin.cn/Works/20201104/sj-4369072618.png"
        ) {
          that.$message("请上传首屏内容");
          return false;
        }
        var btmbkimg="";
        if (
          that.ztbkimg ==
          "https://task.hozoin.cn/Works/20201104/sj-4369072618.png"
        ) {
          btmbkimg="";
        }else{
          btmbkimg=that.ztbkimg ;
        }
        that.banjivalul="";
      };
      var draw_info={
        logo:that.tlogomimg,
        textimg:that.sptextimg,
        topbkimg:that.spbtmimg,
        btmbkimg,
      };
       draw_info=JSON.stringify(draw_info);

      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/getDrawAdd",
        method: "post",
        data: {
          title: that.zl_name, //展评标题
          d_type: that.zl_type, //展评类型 1毕设展 2作品展览
          draw_pic: that.fmimg, //封面地址
          school: that.schoolid,
          banner: that.banimg, //banner
          draw_start: that.startend[0], //开始时间
          draw_end: that.startend[1], //结束时间
          host_unit: that.zl_danwei, //主办单位
          link_man: that.zl_user, //联系人
          phone: that.zl_phone, //联系电话
          drawtext: that.jiyutext, //学院简介
          send_word: that.textareaall, //展览简介
          draw_background: that.bjcolor, //背景色
          word_img: that.jjimg, //展览配图
          draw_img: that.jyimg, //学院配图
          class_school: that.banjivalul, //班级
          major_school: that.zhuanyevalul, //专业
          water_img: that.shuiyinimg, //水印
          draw_man_type: that.zl_xinfen, //2个人展 1多人展
          draw_color: that.dhtextcolor, //字体颜色
          draw_userid: that.gerenzhanid_id, //展览人绑定
          draw_info,//个人展资料信息
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.$message("展览已提交审核！");
          that.$router.push({ path: "Userhome/Userhl_zhanlan" });
        } else {
          that.$message(res.data.messages);
        }
      });
    },
    schoolfocus() {
      this.schoolhidden = 0;
    },
    schoollbliclick(school, id) {
      this.school = school;
      this.schoollbtype = false;
      this.schoolhidden = 1;
      this.schoolid = id;
    },
    banjiclick(id) {
      if (id == "0") {
        var banjivalall = this.banjival;
        //回车分割
        this.banjivalul = banjivalall.split(/[(\r\n)\r\n]+/);
        this.dialogVisible = false;
      } else {
        var zhuanyevalall = this.zhuanyeval;
        //回车分割
        this.zhuanyevalul = zhuanyevalall.split(/[(\r\n)\r\n]+/);
        this.zhuanyedialog = false;
      }
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
  },
  watch: {
    zl_type() {
      if (this.zl_type == 1) {
        this.schooltype = true;
        this.gerenzhanid_type = false;
      } else {
        this.schooltype = false;
        this.gerenzhanid_type = false;
      }
    },
    school: function () {
      var that = this;
      if (that.schoolhidden == 0) {
        if (that.school == "") {
          that.schoollbtype = false;
        } else {
          that.schoollbtype = true;
          that
            .$axios({
              headers: { "X-CSRF-TOKEN": that.userinfo.token },
              url: "/api/getSchoolList",
              method: "post",
              data: {
                name: that.school,
              },
            })
            .then((res) => {
              if (res.data.code == 1) {
                that.schoollbli = res.data.result;
              } else {
              }
            });
        }
      } else {
      }
    },
    zl_xinfen() {
      if (this.zl_xinfen == "1") {
        this.gerenzhanid_type = false;
      } else {
        this.gerenzhanid_type = true;
      }
    },
    gerenzhanid() {
      var that = this;
      that.gerenzhanid_id = "";
      that.gerenzhanid_idoptions =[];
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getSearchUserInfo",
          method: "post",
          data: {
            username: that.gerenzhanid,
          },
        }).then((res) => {
          if (res.data.code == 1) {
            that.gerenzhanid_idoptions = res.data.result.list;
          }
        });
    },
  },
};
</script>
